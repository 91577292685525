<template>
  <div id="app">
		<head-nav v-if="showNav" />
    <router-view/>
		<foot-nav />
  </div>
</template>

<script>
	import {getBaseInfo} from '@/api/common'
	import headNav from '@/components/head/headNav.vue'
	import footNav from '@/components/footNav.vue'
	export default {
		components: {
			headNav,
			footNav
		},
		data() {
			return {
				exclus: [
					{name: 'home', path: '/'},
					{name: 'login', path: '/login/login'},
					{name: 'register', path: '/login/register'},
				]
			}
		},
		computed: {
			showNav() {
				let cls = this.exclus.filter(item => {
					return this.$route.name == item.name || this.$route.path == item.path
				})
				return !cls.length
			}
		},
		created() {
			// this.getCopyRight()
		},
		methods: {
			getCopyRight() {
				getBaseInfo({key: 'copyright'}).then(res => {
					if (res.code == 1) {
						this.$store.dispatch('setCopyright', res.data)
					}
				})
			}
		},
	}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
	color: #333;
}
</style>
