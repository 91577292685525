import CONFIG from '@/config/index.js'
import request from '@/utils/request.js'

const api = CONFIG.api

export function getBaseInfo(data) {
	return request({
		url: `${api}/common/getBaseInfo`,
		method: 'post',
		data
	})
}
// 图形验证码
export function imgCaptcha(data) {
	return request({
		url: `https://rsks.shiyan.gov.cn/api/common/get_captcha`,
		method: 'post',
		data
	})
}
// 获取分类
export function getType(params) {
	return request({
		url: `${api}/category/index`,
		method: 'get',
		params
	})
}
// 区域列表
export function getArea(data) {
	return request({
		url: `${api}/area/index`,
		method: 'post',
		data
	})
}
// 应用中心
export function getApplication(params) {
	return request({
		url: `${api}/index/getLinks`,
		method: 'get',
		params
	})
}
// 地图分类
export function getMapType(params) {
	return request({
		url: `${api}/category/list`,
		method: 'get',
		params
	})
}
// 地图数据
export function getMapList(data) {
	return request({
		url: `${api}/map/index`,
		method: 'post',
		data
	})
}
/*** 警务资讯 ***/
export function getNews(data) {
	return request({
		url: `${api}/news/getList`,
		method: 'post',
		data
	})
}
export function newsDetail(params) {
	return request({
		url: `${api}/news/getContent`,
		method: 'get',
		params
	})
}
/*** 媒体矩阵 ***/
export function getMedia(params) {
	return request({
		url: `${api}/media/getList`,
		method: 'get',
		params
	})
}

// 警务资讯点赞
export function thumbNews(data) {
	return request({
		url: `${api}/news/Likes`,
		method: 'post',
		data
	})
}
// 安全活动点赞
export function thumbActive(data) {
	return request({
		url: `${api}/activity/Likes`,
		method: 'post',
		data
	})
}
// 安全宣传点赞
export function thumbAdvert(data) {
	return request({
		url: `${api}/art.archives/Likes`,
		method: 'post',
		data
	})
}
// 法律知识点赞
export function thumbLawer(data) {
	return request({
		url: `${api}/knowledge/Likes`,
		method: 'post',
		data
	})
}