import axios from 'axios'
import { Message } from 'element-ui'
import CONFIG from '@/config/index'
import { getToken } from '@/utils/auth.js'

const service = axios.create({
	baseURL: '',
	headers: {
		// 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
	},
	// responseType: 'json', // 响应数据类型：arraybuffer、document、json、text、stream、blob(浏览器专属)
	withCredentials: true, // 跨域请求使用凭证 cookie
	crossDomain: true,
	// timeout: 5000
})

// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 请求拦截器
service.interceptors.request.use(config => {
	let token = getToken();
	if (token) config.headers['token'] = token;
	if (config.url.indexOf('https://') == -1 && config.url.indexOf('http://') == -1) {
		config.baseURL = CONFIG.baseUrl
	}
	return config
}, error => {
	return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(response => {
	if (response.data.code == undefined) {
		return Promise.resolve(response.data)
	}
	if (response.data.code != 1) {
		// if (response.data.code == 401) {
		// 	Message({
		// 		message: '暂无权限',
		// 		type: 'error',
		// 		duration: 5 * 1000
		// 	})
		// 	return
		// }
		Message({
		  message: response.data.msg,
		  type: 'error',
		  duration: 5 * 1000
		})
		return Promise.resolve(response.data)
	}
	return Promise.resolve(response.data)
}, error => {
	if (error.response.status == 401 || error.response.data.code == 401) {
		Message({
		  message: error.response.data.msg,
		  type: 'error',
		  duration: 5 * 1000
		})
		console.log('error', error)
		return Promise.resolve(error.response)
	}
	return Promise.reject(error)
})

export default service
