import {getToken, getUser} from '@/utils/auth.js'
export default {
	data() {
		return {}
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo
		},
		token() {
			return this.$store.state.user.token
		},
	},
	methods: {
		searchKeyword() {
			this.$store.dispatch('setKeywords', this.keywords)
			this.$router.push('/search')
		},
		handleMenu(item, index) {
			this.$store.dispatch('setMenu', index)
			this.$router.push(item.link)
		},
		login() {
			this.$router.push({name: 'login'})
		},
		register() {
			this.$router.push({name: 'register'})
		},
		logout() {
			this.$confirm('确认退出?', '提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$store.dispatch('userLogout').then(res => {
					if (res.code == 1) {
						this.$message.success('退出成功')
						this.$router.push('/')
					}
				})
			}).catch(() => {
				// this.$message({
				// 	type: 'info',
				// 	message: '已取消'
				// });
			});
		},
		showUser() {
			this.$router.push('/user')
		}
	}
}