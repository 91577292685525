<template>
	<div class="copy-right">
		<div v-html="copyright"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		computed: {
			copyright() {
				return this.$store.state.app.copyright 
			}
		},
	}
</script>

<style lang="scss" scoped>
</style>