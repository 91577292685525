const config = {
	development: {
		baseUrl: 'https://wsga.test.10tuan.net',
		imgUrl: 'https://bps.shiyan.gov.cn',
		api: '/api',
		amapKey: '468363da57369136242f398d64c45f7b',
		amapVersion: '2.0',
		sm4Key: 'Xk1y0Jtzucafns1z2N3Gf7olqX0UQog8'
	},
	production: {
		baseUrl: '',
		imgUrl: 'https://bps.shiyan.gov.cn',
		api: '/api',
		amapKey: '468363da57369136242f398d64c45f7b',
		amapVersion: '2.0',
		sm4Key: 'Xk1y0Jtzucafns1z2N3Gf7olqX0UQog8'
	}
}

export default config[process.env.NODE_ENV]