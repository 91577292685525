const state = {
	site: {
		name: '十堰公安政务服务平台',
		logo: require('@/assets/logo.png')
	},
	menuIdx: sessionStorage.getItem('midx'),
	listType: [
		{name: '警务要闻', type: ''},
		{name: '工作动态', type: ''},
		{name: '通知公告', type: ''},
		{name: '警察故事', type: ''},
	],
	contentType: sessionStorage.getItem('contentType'),
	tabCate: sessionStorage.getItem('tabCate'),
	keywords: '',
	copyright: ''
}

const mutations = {
	SET_MENU(state, index) {
		state.menuIdx = index
	},
	SET_TYPE(state, type) {
		state.contentType = type
	},
	SET_TAB_CATE(state, cate) {
		state.tabCate = cate
	},
	SET_KEYWORDS(state, keywords) {
		state.keywords = keywords
	},
	SET_COPYRIGHT(state, copyright) {
		state.copyright = copyright
	}
}

const actions = {
	setMenu({commit}, index) {
		sessionStorage.setItem('midx', index)
		commit('SET_MENU', index)
	},
	setType({commit}, type) {
		sessionStorage.setItem('contentType', type)
		commit('SET_TYPE', type)
	},
	setTabCate({commit}, cate) {
		sessionStorage.setItem('tabCate', cate)
		commit('SET_TAB_CATE', cate)
	},
	setKeywords({commit}, keywords) {
		commit('SET_KEYWORDS', keywords)
	},
	setCopyright({commit}, copyright) {
		commit('SET_COPYRIGHT', copyright)
	}
}

export default {
	namespace: true,
	state,
	actions,
	mutations
}