<template>
	<div class="header_nav">
		<div class="head_nav_inner banner-container">
			<a class="site_info" href="/">
				<img class="site_logo" alt="logo" :src="site.logo">
				<span class="site_name">{{site.name}}</span>
			</a>
			<div class="nav_wrap">
				<div class="menus">
					<div class="menu_item" v-for="(mitem, mindex) in menus" :key="mindex">
						<span :class="['text', {'active': activeMenu == mindex}]" @click="handleMenu(mitem, mindex)">{{mitem.name}}</span>
					</div>
				</div>
			</div>
			<div class="search_wrap">
				<div :class="['search_box', {'search_login': token}, {'search_focus': focused}]">
					<el-input
						v-model.trim="keywords"
						@keyup.enter.native="searchKeyword"
						@focus="focused = true"
						@blur="focused = false"
						:placeholder="focused ? '请输入您要搜索的内容' : '搜索'">
						<i slot="suffix" class="icon_search el-icon-search" @click.stop="searchKeyword"></i>
					</el-input>
				</div>
			</div>
			<div v-if="token" class="user_content">
				<span>欢迎您，</span><span class="name" @click="showUser">{{$utils.encryPhone(userInfo.nickname)}}</span><span class="logout" @click="logout">退出</span>
			</div>
			<div v-if="!token" class="login_content">
				<span class="login btn" @click="login">登录</span>
				<span class="line">|</span>
				<span class="register btn" @click="register">注册</span>
			</div>
		</div>
	</div>
</template>

<script>
	import headNav from './headNav.js'
	export default {
		mixins: [headNav],
		data() {
			return {
				keywords: '',
				tags: [
					{text: '身份证办理'},
					{text: '出生登记'},
					{text: '机动车行驶证换领'},
					{text: '交通违法查询'},
				],
				menuMode: 'horizontal',
				bgc: 'transparent',
				textColor: '#fff',
				atColor: '#FFAB1B',
				menus: [
					{id: 1, name: '首页', link: '/'},
					{id: 2, name: '政务服务', link: '/serve'},
					{id: 3, name: '安全宣传', link: '/advert'},
					{id: 4, name: '警务资讯', link: '/news'},
					{id: 5, name: '警务地图', link: '/map'},
					{id: 6, name: '媒体矩阵', link: '/media'},
				],
				focused: false
			}
		},
		computed: {
			site() {
				return this.$store.state.app.site
			},
			activeMenu() {
				this.menus.forEach((menu, index) => {
					if (menu.link == this.$route.path) {
						this.$store.dispatch('setMenu', index)
					}
				})
				return this.$store.state.app.menuIdx
			}
		},
		methods: {},
	}
</script>

<style lang="scss" scoped>
	.header_nav {
		color: #fff;
		background: linear-gradient(to bottom, #2493F9, #369FFF);
		// background-color: rgba(34, 34, 67, .5);
		background: rgba(27, 51, 136, .9);
		.head_nav_inner {
			height: 80px;
			line-height: 80px;
			display: flex;
			align-items: center;
		}
		.site_info {
			margin-right: 20px;
			.site_logo {
				margin-right: 5px;
				width: 40px;
				height: 40px;
				vertical-align: middle;
				object-fit: contain;
			}
			.site_name {
				color: #fff;
				font-size: 1.25em;
			}
		}
		.nav_wrap {
			flex: 1;
			padding: 0 5%;
			.menus {
				display: flex;
				align-items: center;
				.menu_item {
					padding: 0 3%;
					height: 80px;
					color: #fff;
					white-space: nowrap;
					display: flex;
					.text {
						box-sizing: border-box;
						cursor: pointer;
						&:hover {
							color: var(--active-color);
						}
						&.active {
							color: var(--active-color);
							border-bottom: 4px solid var(--active-color);
						}
					}
				}
			}
		}
		.search_wrap {
			position: relative;
			.search_box {
				width: 80px;
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
				transition: all .3s;
				&.search_login {
					width: 80px;
				}
				&.search_focus {
					width: 180px;
				}
				.icon_search {
					cursor: pointer;
				}
			}
		}
		.user_content {
			font-size: 14px;
			color: #fff;
			box-sizing: border-box;
			.name {
				padding-right: 10px;
				color: var(--active-color);
				cursor: pointer;
			}
			.logout {
				cursor: pointer;
			}
		}
		.login_content {
			padding: 6px 18px;
			font-size: 14px;
			line-height: 1;
			color: #fff;
			background: rgba(255, 255, 255, .1);
			border-radius: 32px;
			border: 1px solid rgba(255, 255, 255, .3);
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-around;
			.btn {
				cursor: pointer;
			}
			.line {
				margin: 0 12px;
			}
		}
	}
	::v-deep .el-input .el-input__inner {
		padding-right: 20px;
		height: 30px;
		border-radius: 50px;
	}
</style>