import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token) {
	return Cookies.set(TokenKey, token)
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}

export function setUser(info) {
	return localStorage.setItem('userinfo', JSON.stringify(info))
}

export function getUser() {
	return JSON.parse(localStorage.getItem('userinfo'))
}
