<template>
	<div class="footer-nav">
		<div class="banner-container">
			<el-row>
				<el-col :md="3" :lg="3" :xl="3">
					<img class="site-img" src="../assets/site.png" alt="">
				</el-col>
				<el-col :md="7" :lg="7" :xl="7">
					<div class="">主办单位：{{site.zhuban}}</div>
					<div class="">办公地址：{{site.address}}</div>
					<div class="">{{site.beian}}</div>
					<div class="">技术支持：{{site.tec}}</div>
				</el-col>
				<el-col :md="7" :lg="7" :xl="7">
					<div class="">电话：{{site.mobile}}</div>
					<div class="">邮政编码：{{site.code}}</div>
					<!-- <div class="">政府网站标识码：{{site.tell}}</div> -->
					<div class=""><a href="https://beian.miit.gov.cn/" target="_blank">{{site.beianhao}}</a></div>
				</el-col>
				<el-col :md="7" :lg="7" :xl="7">
					<div class="">传真：{{site.tell}}</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import copyRight from '@/components/copyright.vue'
	export default {
		components: {
			copyRight
		},
		data() {
			return {
				site: {
					zhuban: '湖北省十堰市公安局',
					address: '湖北省十堰市朝阳中路43号',
					beian: '鄂公网安备 42030202000140号',
					beianhao: '鄂ICP备06007886号',
					mobile: '0719-8112000',
					code: '442000',
					mark: '4203000022',
					tec: '湖北十团网络科技股份有限公司',
					tell: '0719-8112986'
				}
			}
		},
		methods: {
			getList() {}
		},
	}
</script>

<style lang="scss" scoped>
	.footer-nav {
		padding: 40px 0 30px;
		color: #fff;
		font-size: 14px;
		line-height: 32px;
		background-color: #1B3388;
		.site-img {
			width: 130px;
			height: 130px;
		}
		a {
			color: #fff;
		}
	}
</style>