import CONFIG from '@/config/index.js'
import moment from 'moment'
import JSSM4 from 'jssm4'

/* 图片路径格式化 */
function imgSrc(src) {
  if (!src) return ''
  if (src.indexOf('http://') !== -1 || src.indexOf('https://') !== -1) return src
  if (src.indexOf('data:image') !== -1) return src
  return CONFIG.imgUrl + src
}

/* 手机号加星 */
function encryPhone(mobile) {
	var reg = /(\d{3})\d*(\d{4})/;
	return mobile.replace(reg, '$1 **** $2')
}

/* 日期时间格式化 */
function dateFormat(date, format) {
	if (!date) {
		return ''
	}
	if (!format) {
		return date
	}
	let dateTime = date
	if (String(date).length == 10) {
		dateTime = date * 1000
	}
	return moment(dateTime).format(format)
}

/* 身份证校验 */
function verifyIdCard(code) {
	if (typeof code != 'string') {
		this.showToast({
			title: '参数类型错误'
		});
		return
	}
	code = code.toUpperCase();
	// 前2位城市码
	let city = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外 "
	};
	// 加权因子
	let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
	// 校验位
	let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
	// 身份证简单正则
	let Reg = /^\d{6}(18|19|20)?\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
	// 身份证号码数组
	let arr_code = code.split('');
	// 校验编码为空，简单正则，城市编码
	if (!code || !Reg.test(code) || !city[code.substr(0, 2)] || code.length !== 18) {
		return false;
	}
	// 校验18位身份证需要验证最后一位校验位
	//∑(ai×Wi)(mod 11)
	let sum = 0;
	for (let i = 0; i < 17; i++) {
		sum += arr_code[i] * factor[i];
	}
	if (parity[sum % 11] != arr_code[17]) {
		return false;
	}
	return true;
}

/* 解密 */
function decryptSm4(text) {
  if (!text) {
    return ''
  }
  let sm4 = new JSSM4(CONFIG.sm4Key);
  let dedata = sm4.decryptData_ECB(text);
  return dedata
}

/* 加密 */
function encryptSm4(text) {
  if (!text) {
    return ''
  }
  let sm4 = new JSSM4(CONFIG.sm4Key);
  let endata = sm4.encryptData_ECB(text);
  // console.log(endata)
  return endata
}

export default {
	imgSrc,
	encryPhone,
	dateFormat,
	verifyIdCard,
	decryptSm4,
	encryptSm4
}