import CONFIG from '@/config/index.js'
import request from '@/utils/request.js'

const api = CONFIG.api

// 登录
export function login(data) {
	return request({
		url: `${api}/user/login`,
		method: 'post',
		data
	})
}
// 注册
export function register(data) {
	return request({
		url: `${api}/user/register`,
		method: 'post',
		data
	})
}
// 发送验证码
export function sendCode(data) {
	return request({
		url: `${api}/sms/send`,
		method: 'post',
		data
	})
}
// 验证码登录
export function codeLogin(data) {
	return request({
		url: `${api}/user/mobilelogin`,
		method: 'post',
		data
	})
}
// 退出登录
export function logout(data) {
	return request({
		url: `${api}/user/logout`,
		method: 'post',
		data
	})
}
// 收藏
export function thumbCollect(data) {
	return request({
		url: `${api}/user/collectionToggle`,
		method: 'post',
		data
	})
}