import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'

const routerPush = VueRouter.prototype.push
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(err => {})
}
VueRouter.prototype.replace = function replace(location) {
	return routerReplace.call(this, location).catch(err => {})
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
		meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
  },
  {
    path: '/search',
    name: 'search',
		meta: { title: '' },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/search.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
		meta: { title: '' },
    component: () => import(/* webpackChunkName: "home" */ '../views/detail/detail.vue')
  },
  {
    path: '/serve',
    name: 'serve',
		meta: { title: '政务服务' },
    component: () => import(/* webpackChunkName: "serve" */ '../views/serve/serve.vue')
  },
  {
    path: '/advert',
    name: 'advert',
		meta: { title: '安全宣传' },
    component: () => import(/* webpackChunkName: "advert" */ '../views/advert/advert.vue')
  },
  {
    path: '/activity',
    name: 'activity',
		meta: { title: '安全活动' },
    component: () => import(/* webpackChunkName: "advert" */ '../views/advert/activity.vue')
  },
  {
    path: '/advlist',
    name: 'advlist',
		meta: { title: '' },
    component: () => import(/* webpackChunkName: "advert" */ '../views/advert/list.vue')
  },
  {
    path: '/news',
    name: 'news',
		meta: { title: '警务资讯' },
    component: () => import(/* webpackChunkName: "news" */ '../views/news/news.vue')
  },
  {
    path: '/newslist',
    name: 'newslist',
		meta: { title: '' },
    component: () => import(/* webpackChunkName: "news" */ '../views/news/list.vue')
  },
  {
    path: '/map',
    name: 'pmap',
		meta: { title: '警务地图' },
    component: () => import(/* webpackChunkName: "map" */ '../views/map/index.vue')
  },
  {
    path: '/media',
    name: 'media',
		meta: { title: '媒体矩阵' },
    component: () => import(/* webpackChunkName: "media" */ '../views/media/media.vue')
  },
	{
		path: '/login',
		component: () => import(/* webpackChunkName: login */ '../views/login/index.vue'),
		children: [
			{
				path: 'login',
				name: 'login',
				meta: { title: '' },
				component: () => import(/* webpackChunkName: login */ '../views/login/login.vue'),
			},
			{
				path: 'register',
				name: 'register',
				meta: { title: '' },
				component: () => import(/* webpackChunkName: login */ '../views/login/register.vue'),
			}
		]
	},
  {
    path: '/user',
    name: 'user',
		meta: { title: '个人信息' },
    component: () => import(/* webpackChunkName: "user" */ '../views/user/user.vue')
  },
  {
    path: '*',
    redirect: '/',
  }
]

const router = new VueRouter({
	mode: 'hash',
	scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, form, next) => {
	document.title = Store.state.app.site.name
	if (to.meta && to.meta.title) {
		document.title = to.meta.title
		next()
	} else {
		next()
	}
})

export default router
