import {setToken, getToken, setUser, getUser, removeToken} from '@/utils/auth.js'
import {login, codeLogin, logout} from '@/api/user'

const state = {
	token: getToken(),
	userInfo: getUser()
}

const mutations = {
	SET_TOKEN(state, token) {
		state.token = token
		setToken(token)
	},
	SET_USER(state, user) {
		state.userInfo = Object.assign({}, user)
		setUser(Object.assign({}, user))
	},
	LOGOUT(state) {
		state.token = ''
		state.userInfo = {}
		removeToken()
	}
}

const actions = {
	login({commit}, form) {
		return new Promise((resolve, reject) => {
			login(form).then(res => {
				if (res.code == 1) {
					commit('SET_USER', res.data.userinfo)
					commit('SET_TOKEN', res.data.userinfo.token)
				}
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	mobileLogin({commit}, form) {
		return new Promise((resolve, reject) => {
			codeLogin(form).then(res => {
				if (res.code == 1) {
					commit('SET_USER', res.data.userinfo)
					commit('SET_TOKEN', res.data.userinfo.token)
				}
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	setUserInfo({commit}, userinfo) {
		commit('SET_USER', userinfo)
		commit('SET_TOKEN', userinfo.token)
	},
	userLogout({commit}) {
		return new Promise((resolve, reject) => {
			logout().then(res => {
				commit('LOGOUT')
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	}
}

export default {
	namespace: true,
	state,
	mutations,
	actions
}